<template>
  <div id="app">
    <Header/>
    <div id="main">
      <transition mode="out-in">
        <router-view ref="routerView" :key="$route.fullPath"/>
      </transition>
    </div>
    <Footer />
    <img class="xs-img" draggable="false" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/%E6%98%9F%E7%90%83%E6%89%8B%E6%9C%BA%E4%B8%BB%E9%A1%B5.jpg" alt="" srcset="">
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import elementResizeDetectorMaker from "element-resize-detector";
export default {
  components: {
    Header,
    Footer,
  },
  name: '',
  computed:{
   
  },
  data(){
    return {
      windowWidth:1000
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0)
    }
  },
  mounted() {
    let t = 1920
    // if(window.screen.width <= 768){
    //   t = 375
    // }
    // let clientWidth = document.documentElement.clientWidth
    // let fs = clientWidth / t * 100;
    // if(fs > 100){
    //   fs =  100
    // }
    // document.documentElement.style.fontSize = fs + 'px'

    this.$nextTick(()=>{
      this.watchSize()
    })
    setInterval(()=>{
      $('#video').bind('contextmenu',function() { return false; });
    },100)
  },
  methods: {
    //监听内容部分的宽度
    watchSize(){
      var erd = elementResizeDetectorMaker();
      erd.listenTo(document.getElementById("app"), (element) => {
        this.windowWidth = element.offsetWidth;
        console.log(this.windowWidth)
        let fs = this.windowWidth / 1920 * 100;
        if(fs > 100){
          fs =  100
        }
        document.documentElement.style.fontSize = fs + 'px'
      });
    },
  },
}
</script>
<style lang="less">
@font-face {
  font-family: "SourceHanSansCN";
  src: url("https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/SourceHanSansCN-Light.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "SourceHanSansCN";
  src: url("https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/SourceHanSansCN-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SourceHanSansCN";
  src: url("https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/SourceHanSansCN-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SourceHanSansCN";
  src: url("https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/SourceHanSansCN-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
html,body{
  background-color: #060708!important;
  color: #fff;
}
:root{
  --color-primary: #f3b818;
  --color-background: #060708;
}
#app {
  font-family: "SourceHanSansCN","Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  font-weight: 400;
  #main{
    width: 100%;
    min-height: 100vh;
    padding-bottom: 1rem;
  }
}

*{
  transition-property: color,background-color,border,opacity;
  transition-duration: 0.1s;
  transition-timing-function:ease;
  position: relative;
}

a{
  text-decoration: none !important;
  line-height: 1 !important;
}

//图片懒加载相关
img[lazy]{
  transition-duration: 1s;
}
img[lazy=loading] {
  opacity: 0;
}
img[lazy=loaded] {
  opacity: 1!important;
}

.ele-msg{
  color: #000;
  font-size: 18px;
}
.el-loading-mask{
  .el-icon-loading{
    font-size: 40px;
  }
}

.xs-img{
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
}
@media (max-width: 750px) {
  .xs-img{
    display: block;
    width: 100%;
  }
  .header,.footer{
    display: none;
  }
  #main{
    display: none;
  }
}
</style>
