<template>
  <div class="footer">
    <div class="r1">
      <div class="container">
        <div class="logobox ">
          <img draggable="false" src="@/assets/img/logo.png" class='logo' @click="goto('/')" alt="星球研究所 Logo">
        </div>
        <div class="linkbox">
          <div class="box">
            <img draggable="false" class="b" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/btn%E5%85%AC%E4%BC%97%E5%8F%B7.png" alt="" srcset="">
            <div class="hoverbox">
              <img draggable="false" slot="content" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/%E5%85%AC%E4%BC%97%E5%8F%B7.jpg" alt="" srcset="">
            </div>
          </div>
          <div class="box">
            <img draggable="false" class="b" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/btn%E8%A7%86%E9%A2%91.png" alt="" srcset="">
            <div class="hoverbox">
              <img draggable="false" slot="content" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/%E8%A7%86%E9%A2%91%E5%8F%B7.jpg" alt="" srcset="">
            </div>
          </div>
          <div class="box">
            <a href="https://space.bilibili.com/326427334" target="_blank">
              <img draggable="false" class="b" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/btnb%E7%AB%99.png" alt="" srcset="">
            </a>
          </div>
          <div class="box">
            <a href="https://weibo.com/u/2210496261" target="_blank">
              <img draggable="false" class="b" src="https://iloveplanet.oss-cn-hangzhou.aliyuncs.com/resources/btn%E5%BE%AE%E5%8D%9A.png" alt="" srcset="">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="r2">
      <div class="auth container">
        <div class="row">
          <div class="col-md-12 col-xl-6">
            <h4>商业合作：邮箱hongtianxiang@iloveplanet.cn
              <div class="hoverbox">请在邮件中简要说明合作项目、公司名称、联系人职位</div>
            </h4>
            <h4>转载/投稿/社群：微信geographyinmyheart</h4>
          </div>
          <div class="col-md-12 col-xl-6">
            <h5>©版权所有 Copyright(C)星球研究所</h5>
            <h5><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备18045549号-1</a> | <a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"><img src="@/assets/img/record_logo.png" alt="" srcset=""> 京公网安备 11011402013458号</a></h5>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    
  },
  computed:{
    
  },
  data(){
    return {
      
    }
  },
  mounted() {

  },
  methods:{
    goto(target) {
      console.log(this.$route)
      if(this.$route.path !== target){
        this.$router.push(target)
      }
    },
  }
}
</script>
<style scoped lang="less">
.footer{
  z-index: 1000;
  width: 100%;
  background-color: #262727;
  min-height: 230px;
  color: #fff;
  .r1{
    width: 100%;
    border-bottom: 2px solid #313131;
    .container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      .logobox{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        user-select: none;
        .logo{
          height: 33px;
        }
      }
      .linkbox{
        display: flex;
        align-items: center;
        justify-content: center;
        .box{
          width: 40px;
          height: 40px;
          margin-left: 7px;
          background-color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          a{
            width: 40px;
            height: 40px;
            display: block;
            line-height: 0;
            font-size: 0;
          }
          .b{
            width: 40px;
            height: 40px;
            opacity: 0.5;
            cursor: pointer;
            line-height: 0;
            font-size: 0;
          }
          .hoverbox{
            display: none;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -90px;
            width: 90px;
            height: 90px;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            img{
              width: 85px;
            }
          }
          &:hover{
            .b{
              opacity: 1;
            }
            .hoverbox{
              display: flex;
            }
          }
        }
      }
    }
  }
  .r2{
    margin-top: 18px;
    .auth{
      color:#A4A9B4;
      line-height:16px;
      .hoverbox{
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -44px;
        width: 210px;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 5px 10px;
        font-size: 0.14rem;
        font-weight: 400;
        color:#A4A9B4;
        line-height:16px;
        img{
          width: 85px;
        }
      }
      &:hover{
        .b{
          opacity: 1;
        }
      }
      .col-md-12{
        line-height: 0;
        font-size: 0;
      }
      h5{
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: right;
        img{
          width: 14px;
          margin-left: 5px;
        }
      }
      h4{
        font-size: 14px;
        margin-bottom: 10px;
        display: inline-block;
        &:hover{
          .hoverbox{
            display: flex;
          }
        }
      }
      a{
        color: #A4A9B4;
        text-decoration: none;
      }
    }
  }
}
@media (max-width: 1200px) {
  h4,h5{
    text-align: center!important;
  }
}
</style>
