export default {
  setToken(state, value) {
    localStorage.setItem("token",value)
    state.token = value;
  },
  
  clearToken(state, value) {
    localStorage.setItem("token","")
    state.token = "";
    state.user = ""
  },

  setUser(state, value) {
    state.user = value;
  },
}