export default {
  //显示loading
  showLoading(){
    if(!window.loading){
      window.loading = window.$VUE.$loading({
        lock: true,
        text: '请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.2)'
      });
    }
  },
  //隐藏loading
  hideLoading(){
    window.loading && window.loading.close()
    window.loading = ""
  },
  //错误提示
  error(text){
    this.hideLoading()
    window.$VUE.$message.error({
      message: text,
      type: 'error',
      customClass: 'ele-msg'
    });
  },
  //提示消息
  message(text){
    this.hideLoading()
    window.$VUE.$message(({
      message: text,
      customClass: 'ele-msg'
    }));
  },
}