import Vue from 'vue'
import Vuex from 'vuex'
import store from './store';
import ElementUI from 'element-ui';
import './assets/theme/index.css'
import './assets/theme/custom.less';
import App from './App.vue'
import router from './router'
//网络
import axios from "axios";
import VueAxios from 'vue-axios'
import url from "./assets/url.js";
//懒加载
import VueLazyload from 'vue-lazyload'
//视频播放器
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
//剪贴板
import VueClipboard from 'vue-clipboard2'
//序列化工具
import Qs from 'qs'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import 'quill/dist/quill.min.js'

Vue.use(Vuex)
//bootstrap
import * as bootstrap from 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"

//一些组件
import method from '@/assets/methods.js';

//关闭vue提示
Vue.config.productionTip = false

//网络设置
axios.defaults.baseURL = url

axios.interceptors.response.use(function (response) {
  
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error);
});
window.axios = axios
window.baseurl = url
window.qs = Qs
Vue.use(VueAxios, axios)

//懒加载
Vue.use(VueLazyload, {
  preLoad: 1.3,
  observer: true,
  // lazyComponent: true,
  attempt: 1,     // 加载图片数量
  // throttleWait:2000
})

//element
Vue.use(ElementUI);

//视频组件
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

//剪贴板
Vue.use(VueClipboard)

//自定义组件
Vue.prototype.$method = method
Vue.prototype.$store = store
// Vue.prototype.$bootstrap = bootstrap
//全局事件总线
Vue.prototype.$eventHub= Vue.prototype.$eventHub || new Vue()

window.$VUE = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) ,3), //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}

Vue.mixin({
  methods: {
    getUrlParams(text) { // 不传text返回所有值，否则返回对应值
      var url = window.location.search || window.location.hash;
      if (url.indexOf('?') == -1) { return false; }
      url = url.split("?")[1];
      url = url.split('&');
      var name = text || '';
      var urlobj = {}
      var nameres;
      // 获取全部参数及其值
      for(var i=0;i<url.length;i++) {
        var info = url[i].split('=');
        var obj = {};
        obj[info[0]] = decodeURI(info[1]);
        Object.assign(urlobj,obj)
      }
      // 如果传入一个参数名称，就匹配其值
      if (name) {
        nameres = urlobj[name]
      } else {
        nameres = urlobj;
      }
      // 返回结果
      return nameres;
    }
  },
})

if(process.env.VUE_APP_GET_ANTI_DEBUGGER_CODE){
  window.VUE_APP_GET_ANTI_DEBUGGER_CODE = true
}