<template>
  <div class="header" :class="path=='/' && 'active'">
    <div class="logobox">
      <img draggable="false" src="@/assets/img/logo.png" class='logo' @click="goto('/')" alt="星球研究所 Logo">
    </div>
    <div class="headerbox" :class="path=='/' && 'justify-content-center'">
      <div class="nav" :class="path=='/' && 'active'" @click="goto('/')">
        <span>首页</span>
      </div>
      <div class="nav" :class="path.includes(item.id) && 'active'" @click="goto(item.path)" v-for="(item,index) in navList" :key="index">
        <span>{{item.title}}</span>
      </div>
      <div class="rightbox" v-if="path!=='/'">
        <div class="nav-search d-none d-xl-flex">
          <el-input class="search" v-model="keyword" @keyup.enter.native="keyEnter" size="mini" placeholder="搜索">
            <el-select slot="prepend" v-model="searchType">
              <el-option label="视频" value="Video"></el-option>
              <el-option label="图文" value="Article"></el-option>
              <el-option label="品牌合作" value="Brand"></el-option>
            </el-select>
            <i slot="suffix" class="el-icon-search" @click="keyEnter"></i>
          </el-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Header',
  props: {

  },
  computed:{
    path(){
      return this.$route.path
    },
    navList(){
      return [
        {
          id:'/Video',
          path:'/Video/VideoHome',
          title:'视频'
        },
        {
          id:'/Article',
          path:'/Article/ArticleHome',
          title:'图文'
        },
        {
          id:'/Book',
          path:'/Book',
          title:'图书'
        },
        {
          id:'/Culture',
          path:'/Culture',
          title:'文创'
        },
        {
          id:'/Brand',
          path:'/Brand/BrandHome',
          title:'品牌合作'
        },
        {
          id:'Join',
          path:'/Join',
          title:'加入我们'
        },
      ]
    }
  },
  watch: {
    $route(val){
      console.log('watch',val)
      this.keyword = this.$route.query.t || ""
    }
  },
  data(){
    return {
      keyword:"",
      searchType:"Video",
    }
  },
  mounted(){
    
  },
  methods:{
    goto(target) {
      console.log(this.$route)
      if(target.indexOf('http')>-1){
        window.open(target)
        return
      }
      if(this.$route.path !== target){
        this.$router.push(target)
      }
    },
    
    //回车
    keyEnter(){
      console.log(this.$route.path)
      let key = {
        Video:'/Video/VideoList',
        Article:'/Article/ArticleList',
        Brand:'/Brand/BrandList',
        Series:'/Brand/SeriesList',
      }
      this.$router.push({
        path:key[this.searchType],
        query:{
          t:this.keyword
        }
      })
    },
  }
}
</script>
<style scoped lang="less">
*{
  transition: all 0.4s ease;
  user-select: none;
}
.header{
  position: relative;
  z-index: 1010;
  width:100%;
  padding: 1px 0;
  height: 106px;
  color: #fff;
  border-bottom: 1px solid #6e6f70;
  .logobox{
    position: absolute;
    top: 50%;
    transform: translate(0%,-50%);
    left: calc((100vw - 1200px) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    .logo{
      height: 33px;
    }
  }
  .headerbox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    .nav{
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 16px;
      line-height: 1;
      margin: 0 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #6e6f70;
      height: 105px;
      color: #999;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        cursor: pointer;
      }
      &:hover{
        color: #fff;
      }
      &.active{
        color: #fff;
        border-bottom: 2px solid var(--color-primary);
      }
    }
    .rightbox{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc((100vw - 1200px) / 2);
      /deep/.search{
        border: 1px solid #6e6e6e;
        border-radius: 3px;
        .el-input-group__prepend{
          background-color: transparent;
          color: #fff;
          outline: none;
          border: none;
          .el-select{
            width: 100px;
            .el-input__inner{
              border: none;
              padding-left: 14px;
            }
          }
        }
        .el-input__inner{
          background-color: transparent;
          color: #fff;
          border: none;
          outline: none;
          border-left: 1px solid #6e6e6e;
          width: 200px;
        }
        .el-icon-search{
          cursor: pointer;
          margin-top: 8px;
          margin-right: 3px;
        }
      }
    }
  }
  &.active{
    height: 215px;
    .logobox{
      left: 50%;
      transform: translate(-50%,0%);
      top: 86px;
      .logo{
        height: 41px;
      }
      h1{
        font-size: 32px;
      }
    }
    .headerbox{
      margin-top: 151px;
      .nav{
        height: 63px;
        span{
          margin-top: 0;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .header{
    padding-right:30px;
    padding-left:30px;
    .logobox{
      left: 30px;
    }
    .headerbox{
      justify-content: flex-end;
    }
    .rightbox{
      display: none;
    }
  }
}
</style>
