import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue'),
    },
    {
      path: '/Video/VideoHome',
      name: 'VideoHome',
      component: () => import('../views/Video/VideoHome.vue'),
    },
    {
      path: '/Video/VideoList',
      name: 'VideoList',
      component: () => import('../views/Video/VideoList.vue'),
    },
    {
      path: '/Video/VideoDetail',
      name: 'VideoDetail',
      component: () => import('../views/Video/VideoDetail.vue'),
    },
    {
      path: '/Article/ArticleHome',
      name: 'ArticleHome',
      component: () => import('../views/Article/ArticleHome.vue'),
    },
    {
      path: '/Article/ArticleList',
      name: 'ArticleList',
      component: () => import('../views/Article/ArticleList.vue'),
    },
    {
      path: '/Article/ArticleDetail',
      name: 'ArticleDetail',
      component: () => import('../views/Article/ArticleDetail.vue'),
    },
    {
      path: '/Brand/BrandHome',
      name: 'BrandHome',
      component: () => import('../views/Brand/BrandHome.vue'),
    },
    {
      path: '/Brand/BrandList',
      name: 'BrandList',
      component: () => import('../views/Brand/BrandList.vue'),
    },
    {
      path: '/Brand/SeriesList',
      name: 'SeriesList',
      component: () => import('../views/Brand/SeriesList.vue'),
    },
    {
      path: '/Brand/SeriesDetail',
      name: 'SeriesDetail',
      component: () => import('../views/Brand/SeriesDetail.vue'),
    },
    {
      path: '/Brand/DetailVideo',
      name: 'DetailVideo',
      component: () => import('../views/Brand/DetailVideo.vue'),
    },
    {
      path: '/Brand/DetailArticle',
      name: 'DetailArticle',
      component: () => import('../views/Brand/DetailArticle.vue'),
    },
    {
      path: '/Join',
      name: 'Join',
      component: () => import('../views/Join.vue'),
    },
    {
      path: '/JoinDetail',
      name: 'JoinDetail',
      component: () => import('../views/JoinDetail.vue'),
    },
    {
      path: '/Culture',
      name: 'Culture',
      component: () => import('../views/Culture.vue'),
    },
    {
      path: '/CultureDetail',
      name: 'CultureDetail',
      component: () => import('../views/CultureDetail.vue'),
    },
    {
      path: '/Book',
      name: 'Book',
      component: () => import('../views/Book.vue'),
    },
    {
      path: '/BookDetail',
      name: 'BookDetail',
      component: () => import('../views/BookDetail.vue'),
    },
    {
      path: '*',
      component: () => import('../views/404.vue')
    }
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  console.log("路由钩子",to.path)
  document.title = "星球研究所————解构世间万物，探索极致世界"
  next()
})
router.afterEach((to,from,next)=>{
  window.scrollTo(0,0)
})
export default router
