export default {
  /**
   * 用户信息
   */
  token: "",
  /**
   * 从menu拿到的用户信息
   */
  user: {},
}
